$map-color-palette: (
  black: (base: #000),
  white: (base: #fff),
  gray: (
    base:                   #919195,
    light:                  #ececec,
    lighter:                #f7f7f7,
    lightest:               #fafafa,
    dark:                   #4a4a4a,
  ),
  purple: (
    base:                   #5d2669,
    light:                  #90589f,
    dark:                   #511960
  ),
  red: (
    base:                   #ebccd1,
    light:                  #f2dede,
    dark:                   #a94442
  ),
  blue: (
    base:                   #bce8f1,
    light:                  #d9edf7,
    dark:                   #31708f
  ),
  green: (
    base:                   #d6e9c6,
    light:                  #dff0d8,
    dark:                   #3c763d
  )

);

$map-color-emotional: (
  light:                    '',
  stable:                   '',
  positive:                 '',
  calm:                     '',
  balanced:                 '',
  energized:                '',
  assertive:                '',
  royal:                    pal-color(purple),
  dark:                     ''
);

$map-color-social: (
  twitter:                  #00aced,
  facebook:                 #3b5998,
  whatsapp:                 #4dc247
);



// Font size:

$map-font-size: (
  tiny:                     12px,
  small:                    14px,
  base:                     16px,
  medium:                   19px,
  large:                    26px,
  xlarge:                   34px,
  xxlarge:                  50px,
  huge:                     ''
);



// Font weight

$map-font-weight: (
  lightest:                 100,
  lighter:                  200,
  light:                    300,
  normal:                   400,
  bold:                     700,
  bolder:                   700,
  boldest:                  900
);



// Typography

$font-color:                pal-color(gray, dark);
$line-height:               2;

$anchor-color:              pal-color(purple);
$anchor-decoration:         underline;
$anchor-hover-color:        pal-color(purple);
$anchor-hover-decoration:   none;

$paragraph-whitespace:      30px;



// Heading size

$map-heading-size: (
  h1:                       font-size(xxlarge),
  h2:                       font-size(xlarge),
  h3:                       font-size(large),
  h4:                       font-size(medium),
  h5:                       font-size(base)
);

$map-font-family: (
  base:                     ('Ubuntu', sans-serif),
  heading:                  ('Ubuntu', sans-serif),
  quote:                    'Times new roman'
);

$heading-color:             pal-color(purple);
$heading-weight:            font-weight(bold);



// Spacing

$map-spacing: (
  tiny:                     10px,
  small:                    20px,
  base:                     30px,
  medium:                   40px,
  large:                    50px
);



// Application

$hex-body-bg:               pal-color(gray, lightest);
$header-height:             80px;
$border-radius:             4px;


// Boostrap customize

$input-border-radius:       2px;
$input-border-focus:        pal-color(purple);


// Fancybox customize

$fancybox-image-url: '/assets/images/fancybox/';
