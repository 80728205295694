.usp {
  @extend %clean-list-container;

  li {
    $padding-left: 50px;
    position: relative;
    padding-left: $padding-left;
    font-size: font-size(medium);

    &::before {
      $size: 6px;

      position: absolute;
      top: 50%;
      left: $padding-left / 2;
      width: $size;
      height: $size;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: pal-color(purple);
      content: '';
    }
  }
}
