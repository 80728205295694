.shadow-block {
  @extend %box-shadow;
  padding: 5px;
  background-color: pal-color(white);

  &--padding {
    padding: 50px;

    @include xs {
      padding: 20px 25px;
    }
  }
}
