.table {
  td,
  tr {
    vertical-align: top;
  }

  > tbody > tr:first-child > td {
    border-top: 0;
  }
}
