.button {
  display: inline-block;
  position: relative;
  padding: 20px 30px;
  border: solid 1px transparent;
  border-radius: $border-radius;
  outline: none;
  background-color: rgba(pal-color(purple), .1);
  color: pal-color(purple);
  font-weight: font-weight(bold);
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  span {
    position: relative;
    z-index: 10;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    transform: translate(-50%, -50%);
    transition: width .3s;
    background-color: rgba(pal-color(purple), .5);
    content: '';
    z-index: 1;
  }

  &:hover {
    color: pal-color(white);

    &::before {
      width: 100%;
      height: 100%;
    }
  }

  &:focus {
    border-color: pal-color(purple);
    box-shadow: 0 0 8px rgba(pal-color(purple), .6);
  }

  &--block {
    display: block;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
  }
}
