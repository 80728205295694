.project-thumb {
  display: block;
  position: relative;
  height: 200px;
  margin-bottom: 6px;
  padding: 25px;
  transition: background-color .3s;
  background-color: pal-color(purple);
  color: pal-color(white);
  overflow: hidden;

  &--text {
    font-size: font-size(xlarge);
    font-weight: font-weight(bold);
    line-height: 1.2;
    text-decoration: none;

    &:hover {
      text-shadow: 5px 5px rgba(0, 0, 0, .3);

      span {
        text-decoration: none;
      }
    }

    span {
      display: block;
      margin-top: 20px;
      font-size: font-size(base);
      font-weight: font-weight(normal);
      text-decoration: underline;
    }
  }

  &:hover {
    background-color: pal-color(purple, dark);
    color: pal-color(white);
  }

  &:hover &__bg {
    transform: translateY(-50px);
  }

  &:hover &__title {
    transform: translateY(0);
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: transform .3s;
    background-position: center;
    background-size: cover;
  }

  &__title {
    @extend %text-ellipsis;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding-right: 15px;
    padding-left: 15px;
    transform: translateY(100%);
    transition: transform .3s;
    background-color: pal-color(purple);
    color: pal-color(white);
    font-size: font-size(small);
    line-height: 50px;
  }
}
