.striking {
  display: none;
  position: relative;

  &.is-visible {
    display: table;
  }

  &.is-visible + &.is-visible {
    margin-top: 50px;
  }

  &__visual {
    @extend %box-shadow-medium;
    display: block;
    margin-bottom: 30px;
    padding-top: 70%;
    background-position: center;
    background-size: cover;
  }

  &__body {
    vertical-align: bottom;

    @include heading {
      $margin-bottom: 40px;

      position: relative;
      margin-top: 60px;
      margin-bottom: $margin-bottom;
      color: pal-color(gray);
      font-family: font-family(quote);
      font-size: font-size(large);
      font-style: italic;
      font-weight: font-weight(lightest);
      line-height: 1.4em;
      text-transform: none;

      &::before {
        display: block;
        position: absolute;
        bottom: $margin-bottom * -1 / 2;
        left: 0;
        width: 50px;
        height: 5px;
        transform: translateY(50%);
        background-color: pal-color(purple);
        content: '';
      }
    }

    p {
      margin-bottom: 0;
      color: pal-color(gray, dark);
    }
  }

  @include sm { // scss-lint:disable DeclarationOrder
    text-align: center;

    @include heading {
      &::before {
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }

    &__visual {
      width: 70%;
      margin-right: auto;
      margin-left: auto;
      padding-top: 40%;
    }
  }

  @include respond-from($screen-md-min) {
    &.is-visible + &.is-visible {
      margin-top: 100px;
    }

    &--mirror &__body {
      padding-right: 50px;
      padding-left: 0;
    }

    &__visual {
      width: 380px;
      margin-bottom: 0;
    }

    &__body {
      display: table-cell;
      padding-left: 50px;
    }
  }
}
