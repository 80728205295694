html,
body {
  overflow-x: hidden;
}

body {
  padding-top: $header-height;
  background-color: $hex-body-bg;
  color: $font-color;
  font-family: font-family(base);
  font-size: font-size(base);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: font-weight(light);
  line-height: $line-height;
}

@include heading {
  margin: 0 0 .5em;
  color: $heading-color;
  font-weight: $heading-weight;
  text-transform: uppercase;
}

::selection {
  background-color: rgba(pal-color(purple), .7);
  color: pal-color(white);
}

h1 { font-size: heading-size(h1); }

h2 { font-size: heading-size(h2); }

h3 { font-size: heading-size(h3); }

h4 { font-size: heading-size(h4); }

h5 { font-size: heading-size(h5); }

@include xs {
  h1 {
    font-size: font-size(base) * 2;
  }

  h2 {
    font-size: font-size(base) * 1.7;
  }

  h3 {
    font-size: font-size(base) * 1.4;
  }

  h4 {
    font-size: font-size(base) * 1.1;
  }

  h5 {
    font-size: font-size(base) * 1;
  }
}

p { margin: 0 0 $paragraph-whitespace; }

ul,
ol {
  margin-bottom: $paragraph-whitespace;
}

a {
  color: $anchor-color;
  text-decoration: $anchor-decoration;

  &:focus,
  &:hover {
    color: $anchor-hover-color;
    text-decoration: $anchor-hover-decoration;
  }
}

hr {
  border-color: rgba(pal-color(purple), .2);
}
