@mixin icon-color-variant($name, $color) {
  &--#{$name} {
    @include paint-svg($color);
  }
}

@mixin icon-rotate-variant($rotation) {
  &--#{$rotation}cw {
    transform: rotate(#{$rotation}deg);
  }
}

.icon {
  @include icon-rotate-variant(45);
  @include icon-rotate-variant(90);
  @include icon-rotate-variant(135);
  @include icon-rotate-variant(180);
  @include icon-rotate-variant(225);
  @include icon-rotate-variant(270);
  @include icon-rotate-variant(315);

  @include icon-color-variant(white, pal-color(white));
  @include icon-color-variant(royal, emo-color(royal));

  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  line-height: 0;
  text-align: center;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  &--tiny {
    width: 8px;
    height: 8px;
  }

  &--small {
    width: 14px;
    height: 14px;
  }
}
