// -- Margin & padding

$map-distance-selector: (
  'margin-top',
  'margin-bottom',
  'padding-top',
  'padding-bottom'
);

$map-distance-variant: (
  none:       0,
  tiny:       map-get($map-spacing, tiny),
  small:      map-get($map-spacing, small),
  base:       map-get($map-spacing, base),
  medium:     map-get($map-spacing, medium),
  large:      map-get($map-spacing, large)
);

@each $selector in $map-distance-selector {
  @each $variant, $value in $map-distance-variant {
    .h-#{$selector}-#{$variant} {
      #{$selector}: #{$value};
    }
  }
}



// -- Text size

@each $size in $map-font-size {
  .h-fs-#{ nth($size, 1) } {
    font-size: nth($size, 2);
  }
}



// -- Text align

.h- {
  &text-left {
    text-align: left;
  }

  &text-center {
    text-align: center;
  }

  &text-right {
    text-align: right;
  }
}



// -- Font weight

@each $weight in $map-font-weight {
  .h-fw-#{ nth($weight, 1) } {
    font-weight: nth($weight, 2);
  }
}



// -- Text color

@each $color in $map-color-emotional {
  .h-tc-#{ nth($color, 1) } {
    color: nth($color, 2);
  }
}

.h-introduction {
  position: relative;
  margin-bottom: 90px;
  font-size: font-size(medium);
  text-align: center;

  &::before {
    position: absolute;
    bottom: -30px;
    left: 50%;
    width: 100px;
    height: 3px;
    transform: translateX(-50%);
    background-color: pal-color(purple);
    content: '';
  }
}

.h-label {
  @extend .control-label;
}

.is-hidden {
  display: none;
}
