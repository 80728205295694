.filter {
  @extend %horizontal-list-container;

  li {
    @extend %horizontal-list-item;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 5px;
  }

  a {
    $horizontal-padding: 20px;
    $height: 35px;

    display: inline-block;
    padding-right: $horizontal-padding;
    padding-left: $horizontal-padding;
    border: solid 1px pal-color(white);
    border-radius: $height / 2;
    color: pal-color(white);
    line-height: $height;
    text-decoration: none;
    text-transform: lowercase;

    &:hover {
      border-color: rgba(pal-color(white), .8);
      background-color: rgba(pal-color(white), .8);
      color: pal-color(purple);
    }
  }

  .is-active a {
    background-color: pal-color(white);
    color: pal-color(purple);
    font-weight: font-weight(bold);
  }
}
