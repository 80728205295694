.spacer {
  @include spacing(height);
  display: block;

  &--small {
    @include spacing(height, .5);
  }

  &--large {
    @include spacing(height, 2);
  }
}
