.icon-link {
  position: relative;
  padding-left: 40px;
  font-weight: font-weight(bold);
  text-decoration: none;

  &:hover {
    text-decoration: underline;

    .icon {
      left: 5px;
    }
  }

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    transition: left .15s;
  }

  &--right {
    padding-right: 40px;
    padding-left: 0;

    &:hover .icon {
      right: 5px;
      left: auto;
    }

    .icon {
      right: 0;
      left: auto;
      transition: right .15s;
    }
  }
}
