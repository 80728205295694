.modal {
  $spacing: 20px;

  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(pal-color(black), .9);
  z-index: 999;

  &.is-visible {
    display: block;
  }

  &__close {
    position: absolute;
    top: $spacing;
    right: $spacing;
    width: 30px;
    height: 30px;
    transition: transform .15s;
    background-image: url('/assets/images/icon-close.svg');

    &:hover {
      transform: rotate(90deg);
    }
  }

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 80vw;
    height: 100%;
    max-height: 90vh;
    padding: $spacing;
    transform: translate(-50%, -50%);
    background-color: pal-color(white);
    overflow: scroll;

    @include xs {
      max-width: 100%;
    }

    &--video {
      background-color: transparent;
      line-height: 0;

      video {
        width: 100%;
        height: 100%;
      }
    }
  }
}
