%clean-list-container {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

%horizontal-list-container {
  @extend %clean-list-container;
  display: inline-block;
}

%horizontal-list-item { display: inline-block; }

%clearfix {
  display: block;
  height: 0;
  clear: both;
  content: ' ';
  visibility: hidden;
}

%text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

%box-shadow {
  box-shadow: 0 0 20px 0 rgba(pal-color(black), .1);
}

%box-shadow-medium {
  box-shadow: 0 0 20px 0 rgba(pal-color(black), .5);
}
