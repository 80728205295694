.image {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 70%;
  overflow: hidden;

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: transform .3s;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &:hover &__image {
    transform: scale(1.1);
  }

  &__description {
    position: absolute;
    right: 3px;
    bottom: 3px;
    left: 3px;
    padding: 10px;
    background-color: rgba(pal-color(purple), .8);
    color: pal-color(white);
    font-size: font-size(small);
  }
}
