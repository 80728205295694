.alert {
  margin-bottom: 25px;
  padding: 15px;
  border: solid 1px transparent;
  border-radius: $border-radius;
  font-size: font-size(small);

  &--success {
    border-color: pal-color(green);
    background-color: pal-color(green, light);
    color: pal-color(green, dark);
  }

  &--error {
    border-color: pal-color(red);
    background-color: pal-color(red, light);
    color: pal-color(red, dark);
  }

  &--info {
    border-color: pal-color(blue);
    background-color: pal-color(blue, light);
    color: pal-color(blue, dark);
  }
}
