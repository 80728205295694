@mixin heading ($from: 1, $to: 6) {
  $heading: '';
  @for $i from $from through $to {
    $heading: if($heading != '', append($heading, h#{$i}, comma), h#{$i});
  }

  #{$heading} {
    @content;
  }
}

@mixin spacing($property, $factor: 1) {
  $_base-spacing: $container-lg / 12;
  $_spacing: $_base-spacing * $factor;

  #{$property}: $_spacing;

  @include md {
    #{$property}: $_spacing / 2;
  }

  @include respond-to($screen-sm-max) {
    #{$property}: $_spacing / 4;
  }
}

@mixin lg {
  @media(min-width: $screen-lg-min) {
    @content;
  }
}

@mixin md {
  @media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @content;
  }
}

@mixin sm {
  @media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin xs {
  @media(max-width: $screen-xs-max) {
    @content;
  }
}

@mixin respond-from($from) {
  @media(min-width: $from) {
    @content;
  }
}


@mixin respond-to($to) {
  @media(max-width: $to) {
    @content;
  }
}

@mixin paint-svg($fill, $stroke: $fill) {
  rect,
  polygon,
  path {
    fill: $fill;
    stroke: $stroke;
  }

  [fill="none"] rect,
  [fill="none"] polygon,
  [fill="none"] path,
  [fill="none"] {
    fill: none;
  }

  [stroke="none"] path,
  [stroke="none"] polygon,
  [stroke="none"] rect,
  [stroke="none"] {
    stroke: none;
  }
}
