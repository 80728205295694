.page-head {
  position: relative;
  margin-bottom: 100px;
  padding: 70px 0 30px;
  background-color: pal-color(purple);
  text-align: center;

  @include heading {
    color: pal-color(white);
    text-shadow: 5px 5px pal-color(purple, dark);
  }

  &__foot {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100px;
    background-image: url('/assets/images/blur-bottom-purple.png');
    background-repeat: no-repeat;
    background-position: center top;
  }

  @include xs { // scss-lint:disable DeclarationOrder
    padding: 40px 0 0;
  }
}
