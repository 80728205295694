textarea {
  min-height: 150px;
  resize: vertical;
}

.form-control {
  padding: 25px 12px 25px 20px;
}

.control-label {
  font-size: 14px;
  font-weight: font-weight(bold);
  text-transform: uppercase;
}
