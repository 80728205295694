$animation-duration: .5s;

.l-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: $header-height;
  background-color: pal-color(white);
  box-shadow: 0 0 20px 0 rgba(pal-color(black), .2);
  z-index: 100;

  ul {
    @extend %horizontal-list-container;
  }

  li {
    @extend %horizontal-list-item;
  }

  @include respond-from($screen-md-min) {
    padding-right: 30px;
    padding-left: 30px;
    overflow: hidden;

    nav {
      $hover-opacity: .3;

      float: right;
      // opacity: 0;
      // animation: navigationMoveIn $animation-duration;
      // animation-delay: .5s;
      // animation-fill-mode: forwards;

      a {
        display: inline-block;
        position: relative;
        padding-right: 32px;
        padding-left: 32px;
        color: pal-color(purple);
        font-weight: font-weight(bold);
        line-height: $header-height;
        text-decoration: none;
        overflow: hidden;

        &:hover {
          color: pal-color(white);

          &::before {
            opacity: $hover-opacity;
          }
        }

        &::before {
          $height: $header-height / 2;

          position: absolute;
          top: 50%;
          right: 7px;
          left: 7px;
          height: $height;
          transform: translateY(-50%);
          transition: transform .3s, opacity .3s, color .3s;
          border: solid 1px transparent;
          border-radius: $border-radius;
          background-color: pal-color(purple);
          content: '';
          opacity: 0;
          z-index: -1;
        }
      }

      li:last-child > a {
        color: pal-color(white);

        &:hover::before {
          opacity: $hover-opacity;
        }

        &::before {
          opacity: 1;
        }
      }

      .is-active > a {
        color: pal-color(purple);
        font-weight: font-weight(bold);

        &::before {
          border-color: pal-color(purple);
        }
      }
    }
  }

  @include respond-to($screen-sm-max) {
    padding-right: 15px;
    padding-left: 15px;

    nav {
      display: none;
      position: fixed;
      top: $header-height;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: pal-color(white);

      &.is-active {
        display: block;
      }

      ul,
      li,
      a {
        display: block;
      }

      a {
        line-height: 60px;
        text-align: center;
        text-decoration: none;
      }

      li:nth-child(even) a {
        background-color: pal-color(gray, lighter);
      }
    }
  }
}

.l-nav-toggle {
  $spacing: 8px;
  $size: 50px;
  $bar-size: 3px;

  display: none;
  position: relative;
  width: $size;
  height: $size;
  margin-top: ($header-height - $size) / 2;
  float: right;
  border: solid 1px pal-color(gray, light);
  border-radius: $border-radius;

  span {
    display: block;
    position: absolute;
    right: $spacing;
    left: $spacing;
    height: $bar-size;
    transition: all .3s;
    border-radius: $bar-size / 2;
    background-color: pal-color(gray);

    &:nth-child(1) {
      top: $spacing * 1.5;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);

      &::before {
        display: block;
        height: $bar-size;
        transition: transform .3s;
        border-radius: $bar-size / 2;
        background-color: pal-color(gray);
        content: '';
      }
    }

    &:nth-child(3) {
      bottom: $spacing * 1.5;
    }
  }

  &.is-active {
    span:nth-child(1) {
      top: 50%;
      right: $spacing * 2;
      left: $spacing * 2;
      opacity: 0;
    }

    span:nth-child(3) {
      right: $spacing * 2;
      bottom: 50%;
      left: $spacing * 2;
      opacity: 0;
    }

    span:nth-child(2) {
      transform: rotate(45deg);

      &::before {
        transform: rotate(90deg);
      }
    }
  }

  @include respond-to($screen-sm-max) {
    display: block;
  }
}

.l-logo {
  position: absolute;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  opacity: 0;
  animation: navigationMoveIn $animation-duration;
  animation-fill-mode: forwards;

  img {
    height: 100%;
  }
}

.l-splash {
  position: relative;
  min-height: 350px;
  margin-bottom: 100px;
  padding-top: 40%;

  &__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center;
    background-size: cover;
  }

  &__brush {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    transform: translateY(50%);
    background-image: url('/assets/images/blur-purple.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(50%);
    color: pal-color(white);
    font-size: 30px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
    z-index: 1;

    span:last-child {
      display: inline-block;
      color: pal-color(white);
      text-shadow: 0 0 40px pal-color(black), 0 2px 0 pal-color(black);
    }
  }

  @include xs {
    &__inner {
      line-height: 35px;
    }
  }
}

.l-footer {
  $logomark-width: 430px;
  $logomark-height: 470px;

  position: relative;
  padding-top: 50px;
  padding-bottom: 85px;
  background-color: pal-color(gray, light);
  color: pal-color(gray, dark);
  font-size: font-size(small);
  overflow: hidden;

  nav {
    margin-top: -10px;
  }

  ul {
    @extend %clean-list-container;
  }

  li a {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  p {
    margin-bottom: 15px;
    line-height: 2em;
  }

  &__logomark {
    position: absolute;
    right: -65px;
    bottom: -10px;
    width: $logomark-width;
    height: $logomark-height;
    background-image: url('/assets/images/logomark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &__facebook,
  &__betere-schilder {
    display: inline-block;
    height: 60px;
    background-repeat: no-repeat;
    background-size: contain;
    color: pal-color(gray, dark);
    line-height: 60px;

    &:hover {
      color: pal-color(gray);
    }
  }

  &__facebook {
    padding-left: 80px;
    background-image: url('/assets/images/facebook.png');
  }

  &__betere-schilder {
    padding-left: 100px;
    background-image: url('/assets/images/betere-schilder.png');
  }

  @include xs {
    padding-bottom: 45px;
    text-align: center;

    li a {
      padding-bottom: 0;
    }

    &__logomark {
      width: $logomark-width * .6;
      height: $logomark-height * .6;
    }
  }
}

@keyframes navigationMoveIn {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
