.row {
  &--small-gutter {
    $gutter: 6px;
    margin-right: $gutter / 2 * -1;
    margin-left: $gutter / 2 * -1;

    [class^="col-"] {
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }
  }
}
